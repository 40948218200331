import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { TwitterConnect } from '@ionic-native/twitter-connect/ngx';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public googlePlus: GooglePlus,
    private fb: Facebook,
    private tw: TwitterConnect,
    public platform: Platform
    
  ){}

  /*isLoggedin() {
    return firebase.auth().currentUser
  }*/
  public actionCodeSettings = {
    url: 'https://console.rapo.app',
    handleCodeInApp: true,
    iOS: {
      bundleId: 'app.Rapo'
    },
    android: {
      packageName: 'app.rapo'
    }
  }

  doRegister(value){
   return new Promise<any>((resolve, reject) => {
     firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
     .then(
       res => resolve(res),
       err => reject(err))
   })
  }

  doLogin(value){
   return new Promise<any>((resolve, reject) => {
     firebase.auth().signInWithEmailAndPassword(value.email, value.password)
     .then(
       res => resolve(res),
       err => reject(err))
   })
  }

  doLogout(){
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut()
      .then(() => {
        this.firebaseService.unsubscribeOnLogOut();
        resolve();
      }).catch((error) => {
        console.log(error);
        reject();
      });
    })
  }

    doGoogleLogin(){
        return new Promise((resolve, reject) => {
            console.log("ok")
            if (this.platform.is('cordova')) {
                this.googlePlus.login({
                    'scopes': 'profile email', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                    'webClientId': environment.googleWebClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                    'offline': true
                }).then((response) => {
                    const googleCredential = firebase.auth.GoogleAuthProvider.credential(response.idToken);
                    firebase.auth().signInWithCredential(googleCredential)
                        .then((user) => {
                            console.log("user")
                            console.log(user)
                            resolve();
                        });
                },(err) => {
                    console.log("err")
                    console.log(err)
                    reject(err);
                });
            }
            else{
                this.afAuth.auth
                    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                    .then((user) => {
                        console.log("user1")
                        console.log(user)
                        resolve()
                    },(err) => {
                      console.log("err1")
                      console.log(err)
                        reject(err);
                    })
            }
        })
    }

    /*doFacebookLogin(){
      return new Promise((resolve, reject) => {
        if (this.platform.is('cordova')) {
          //["public_profile"] is the array of permissions, you can add more if you need
          this.fb.login(["public_profile"])
          .then((response) => {
            const facebookCredential = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
            firebase.auth().signInWithCredential(facebookCredential)
              .then(user => resolve());
          }, err => reject(err)
          );
        }
        else {
          this.afAuth.auth
          .signInWithPopup(new firebase.auth.FacebookAuthProvider())
          .then(result => {
            //Default facebook img is too small and we need a bigger image
            var bigImgUrl = "https://graph.facebook.com/" + result.additionalUserInfo.profile + "/picture?height=500";
            // update profile to save the big fb profile img.
            firebase.auth().currentUser.updateProfile({
              displayName: result.user.displayName,
              photoURL: bigImgUrl
            }).then(res => resolve()
            ,(err) => {
              reject(err);
            });
          },(err) => {
            reject(err);
          })
        }
      })
    }*/

    doFacebookLogin(){
      console.log("yes 1")
      return new Promise((resolve, reject) => {
        if (this.platform.is('cordova')) {
          console.log("cordova")
          //["public_profile"] is the array of permissions, you can add more if you need
          this.fb.login(["public_profile"])
          .then((response) => {
            console.log("2")
            const facebookCredential = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
            firebase.auth().signInAndRetrieveDataWithCredential(facebookCredential)
              .then(user => {
                console.log("3")
                resolve()
              }, err => {
                console.log("err1")
                console.log(err)
                this.googlePlus.login({
                  'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                  'webClientId': environment.googleWebClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                  'offline': true
              }).then((response) => {
                console.log("2")
                  const googleCredential = firebase.auth.GoogleAuthProvider.credential(response.idToken);
                  firebase.auth().signInAndRetrieveDataWithCredential (googleCredential)
                      .then((user) => {
                          console.log("user")
                          console.log(user)
                          firebase.auth().currentUser.linkAndRetrieveDataWithCredential(err.credential).then(result =>resolve()
                        ,(err) => {
                            reject(err);
                        })
                      });
              },(err) => {
                  console.log("err")
                  console.log(err)
                  reject(err);
              });
              //reject(err)
            });
          }, err => {
              console.log("err1")
              console.log(err)
              this.googlePlus.login({
                'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                'webClientId': environment.googleWebClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                'offline': true
            }).then((response) => {
              console.log("2")
                const googleCredential = firebase.auth.GoogleAuthProvider.credential(response.idToken);
                firebase.auth().signInAndRetrieveDataWithCredential (googleCredential)
                    .then((user) => {
                        console.log("user")
                        console.log(user)
                        firebase.auth().currentUser.linkAndRetrieveDataWithCredential(err.credential).then(result =>resolve()
                      ,(err) => {
                          reject(err);
                      })
                    });
            },(err) => {
                console.log("err")
                console.log(err)
                reject(err);
            });
            //reject(err)
          });
        }
        else {
          console.log(1)
          this.afAuth.auth
          .signInWithPopup(new firebase.auth.FacebookAuthProvider())
          .then(result => {
            console.log("yes")
            //Default facebook img is too small and we need a bigger image
            var bigImgUrl = "https://graph.facebook.com/" + result.additionalUserInfo.profile + "/picture?height=500";
            // update profile to save the big fb profile img.
            firebase.auth().currentUser.updateProfile({
              displayName: result.user.displayName,
              photoURL: bigImgUrl
            }).then(res => resolve()
            ,(err) => {
              console.log("ok")
              this.afAuth.auth.signInWithCredential(err.credential).then(result =>resolve()
              ,(err) => {
                  reject(err);
              })
            });
          },(err) => {
            console.log("ok")
            console.log(err)
            this.afAuth.auth
              .signInWithPopup(new firebase.auth.GoogleAuthProvider())
              .then((user) => {
                  console.log("user1")
                  console.log(user)
                  firebase.auth().currentUser.linkAndRetrieveDataWithCredential(err.credential).then(result =>resolve()
                  ,(err) => {
                      reject(err);
                  })
                  //resolve()
              },(err) => {
                console.log("err1")
                console.log(err)
                  reject(err);
              })
              /*firebase.auth().currentUser.linkAndRetrieveDataWithCredential(err.credential).then(result =>resolve()
              ,(err) => {
                  reject(err);
              })*/
          })
        }
      })
    }
  
    doTwitterLogin(){
      
      return new Promise((resolve, reject) => {
        // if we are in a mobile device we use the twitter native plugin
  
        if (this.platform.is('cordova')) {
          this.tw.login()
            .then((response) => {
              const twitterCredential = firebase.auth.TwitterAuthProvider.credential(response.token, response.secret);
              firebase.auth().signInWithCredential(twitterCredential)
              .then(
                user => resolve(),
                error => reject(error)
              );
            },
            err => {
              console.log(err)
              reject(err);
            }
          );
        }
        else {
          this.afAuth.auth
          .signInWithPopup(new firebase.auth.TwitterAuthProvider())
          .then(result => {
            //Default twitter img is just 48x48px and we need a bigger image https://developer.twitter.com/en/docs/accounts-and-users/user-profile-images-and-banners
            var bigImgUrl = (result.user.photoURL).replace('_normal', '_400x400');
  
            // update profile to save the big tw profile img.
            firebase.auth().currentUser.updateProfile({
              displayName: result.user.displayName,
              photoURL: bigImgUrl
            }).then(res => resolve(),(err) => {
              reject(err);
            });
          },(err) => {
            reject(err);
          })
        }
      })
      
    }

    doVerifyPhoneNumber(verificationId:any,verificationCode:any){
      return new Promise((resolve, reject) => {
        if (this.platform.is('cordova')) {
          var signInCredential = firebase.auth.PhoneAuthProvider.credential(verificationId,verificationCode)
          firebase.auth().signInWithCredential(signInCredential).then(
            user => resolve(),
            error => reject(error)
          );      
        }
      })
    }

    doLoginWithToken(token:any){
      return new Promise((resolve, reject) => {   
                 
          firebase.auth().signInWithCustomToken(token).then(
            user => resolve(),
            error => reject(error)
          );          
      })
    }
    sendLoginemail(email:string){
      return new Promise((resolve, reject) => {   
                
        firebase.auth().sendSignInLinkToEmail(email,this.actionCodeSettings).then(
          user => resolve(),
          error => reject(error)
        );                  
      })
    } 
    signInWithEmailLink(email:string,emaillink:string)  {
      return new Promise((resolve, reject) => {   
                 
        firebase.auth().signInWithEmailLink(email,emaillink).then(
          user => resolve(),
          error => reject(error)
        );          
    })
    } 
}